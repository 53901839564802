<template>
  <div>
    <c-search-box @enter="getList" :isShowSearch="false">
      <template slot="search">
        <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :isFirstValue="false"
            type="search"
            label="담당부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="담당자"
            name="managerId"
            v-model="searchParam.managerId">
          </c-field>
        </div>
        <div v-if="!popupParam.sopFireFightingTypeCd" class="col-xs-6 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label="유형"
            v-model="searchParam.sopFireFightingTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="소방설비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="sopFireFightingId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          mapFlag: false,
          sopFireFightingLocationId: '',
          isIssue: false,
          sopFireFightingTypeCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        processCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N',
        mapFlag: false,
        sopFireFightingLocationId: '',
      },
      grid: {
        columns: [
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '설치번호',
            align: 'center',
            sortable: true,
            style: 'width: 20%',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소화설비',
            align: 'left',
            sortable: true,
            style: 'width: 40%',
            type: 'link'
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '유형',
            align: 'center',
            sortable: true,
            style: 'width: 40%',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '100%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFighting.list.url
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.mapFlag) {
        this.searchParam.mapFlag = this.popupParam.mapFlag;
      }
      if (this.popupParam.sopFireFightingLocationId) {
        this.searchParam.sopFireFightingLocationId = this.popupParam.sopFireFightingLocationId;
      }
      if (this.popupParam.isIssue) {
        this.searchParam.isIssue = this.popupParam.isIssue;
      }
      if (this.popupParam.sopFireFightingTypeCd) {
        this.searchParam.sopFireFightingTypeCd = this.popupParam.sopFireFightingTypeCd;
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '소화설비 상세';
      this.popupOptions.param = {
        sopFireFightingId: row.sopFireFightingId,
      };
      this.popupOptions.target = () => import(`${'./fireFightingMasterDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
